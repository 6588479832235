<template>
    <div class="d-flex justify-content-center p-5 align-content-center">
        <div class="loaders "></div> 
    </div>
    <p class="d-block">Redirecting...</p>

</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    created(){
        this.setCookie('token', this.$route.query.order, 1)
        if(this.$route.query.msg){
          this.setCookie('msg', this.$route.query.msg, 1)
        }
        this.$router.push({ name: 'ControlCenter' })
        
    },
    methods:{
        setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
  },
}
</script>
<style>
.loaders {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #25b09b;
  animation: l15 1s infinite linear;
}
.loaders::before,
.loaders::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loaders::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15{ 
  100%{transform: rotate(1turn)}
}
</style>